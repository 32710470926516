import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageKlondikeBarBrittle= '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/Wright-Brand-Recipe-Klondike-Brittle-Lifestyle-1367×737.jpg';

const RecipePage = () => (
    <Layout metaTitle="Bacon Klondike® Bar Brittle"
        metaDescription="Combine our Hickory Smoked Bacon with white chocolate drizzle and a Klondike® bar to create a tasty and unique dessert. Try this recipe today!"
        metaKeywords=""
    >
        <meta property="og:image" content={imageKlondikeBarBrittle} />
        <link rel="image_src" href={imageKlondikeBarBrittle} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageKlondikeBarBrittle} class='img-responsive' alt="The Wright Brand Klondike Brittle" />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>The Wright<sup>&reg;</sup> Brand Klondike<sup>&reg;</sup> Brittle</h1>

                            <div class="recipe-content">
                                <p >Use Wright® Brand Bacon to spice up every dish, even ice cream!</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        30&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        18-20&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>1 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 strips of Wright® Brand, Thick-Cut Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3 tablespoons white chocolate chips
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Klondike® Bar</span> 
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat convetional oven to 400F.

                </p>
                            <p>
                                2. Place the bacon on a foil lined sheet pan in a single layer, cook for 18 - 20 mins or until cooked to desired crispness.

                </p>
                            <p>
                                3. Remove bacon from oven & place on paper towels to drain excess grease. Chop into medium chunks & set aside.
                </p>
                            <p>
                                4. In a bowl add 3 tablespoons white chocolate chips and microwave 20 seconds at a time mixing the chocolate at each interval until fully melted.

                </p>

                           <p>
                                5. Place Klondike® bar on a small baking sheet and drizzle liberally with white chocolate. Add chunks of Wright® Brand bacon on top so they “stick” to the top. Drizzle more white chocolate on top to decorate.

                </p>

                           <p>
                                6. Immediately place in freezer for 30 minutes, then wrap with plastic wrap and serve when desired.

                </p>


                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
